.customShadow{
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
}
/* Define the main font using CSS variables */
/* :root {
    --main-font: 'Poppins', sans-serif;
  } */
  
  /* Apply the font globally */
  /* body {
    font-family: var(--main-font);
  } */
  
  /* Optionally apply the font to all common elements */
  /* h1, h2, h3, p, div {
    font-family: inherit;  
  } */
  
  /* Ensure the custom components also inherit the font */
  /* .custom-component {
    font-family: inherit;
  } */
  
  /* Hide scrollbar */
.no-scrollbar::-webkit-scrollbar {
    display: none;
}

.no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}


/***user table***/
.userTable{
    @apply bg-white 
}
.userTable th{
    @apply border text-base font-medium
}

.userTable td{
    @apply border text-base text-center
}

/**scrollbar hidden****/
.scrollbar-none::-webkit-scrollbar{
    display: none;
}

/* Add these styles to your CSS file */
/* Add these styles to your CSS file */
/* 
 */

 /* Add these styles to your CSS file */
.hover-card, .click-dropdown {
    position: absolute;
    background: white;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 10px;
    display: none;
    z-index: 1000;
    top: 100%; /* Position it below the hovered item */
    left: 0; /* Adjust as needed */
}

.hover-card.visible, .click-dropdown.visible {
    display: block;
}

.sidebar {
    transition: transform 0.3s ease-in-out;
    transform: translateX(-100%);
  }
  
  .sidebar.active {
    transform: translateX(0);
  }
  

  



.footer{
    height: auto;
    width: 100%;
    background-color:#214C69;
    color: #fff;
}
.top{
    height: 70px;
    background: #F0F4FB;
}

input::placeholder {
    color: #a9a9a9; 
    opacity: 1; 
}


.top p{
    margin-top: 0;
    color: #000000;
}
.footer-top-img p{
    font-size: 15px;
}
.footer-top-img{
    width: 70%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


h3{
    font-size: larger;
    font-weight: 500;
    margin-top: 0;

}
.Special-section, .Account-shipping, .Newsletter {
    display: flex;
    flex-direction: column; 
    align-items: flex-start; 
}

.top{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100px;

}

.top img{
    height: 43px;
    width: 51px;
}
.company-img{
    display: flex;
    align-items: center;
    justify-content: center;
}


 a{
    margin: 5px 0; 
    text-decoration: none;
    color: inherit; 
}
a:hover{
    color: #a9a9a9;
    cursor: pointer;
}

.horizantal{
    padding: 30px 80px;
    display: flex;
    justify-content: space-between;
}
.logo{
    height: 50px;
    width: 120px;
    margin-bottom: 20px;
}
.Download-section h2{
    margin-bottom: 0;
}
.images .img1{
    height: 60px;
    width: 150px;
}
.images .img2{
    padding-bottom: 5px;
    height: 53px;
    width: 153px;
    margin-left: -2px;
}
.text {
    font-size: 15px;
    padding: 8px;
    width: 300px;
    height: 35px;
    border-radius: 10px;
    background-color: transparent;
    border: 2px solid #a9a9a9; 
    color: #ffffff; 
    transition: background-color 0.3s ease; 
}


.btn {
    margin: 10px;
    border-radius: 10px;
    height: 50px;
    padding: 10px;
    border: 2px solid #ffffff; 
    color: #000000; 
    cursor: pointer; 
    transition: background-color 0.3s ease; 
}
h2{
    font-size: larger;
    font-weight: 500;
}


.address{
    
    display: flex;
    height: auto;
   
}
.left{
    height: 50px;
    width: 29%;
}
.right{
    padding-right: 80px;
    /* height: 50px; */
    width: 67%;
}
.up{
    display: flex;
  
}
.conversation{
    display: flex;
    align-items: center;
    justify-content: start;
    width: 60%;
}

.up p{
margin: 0px;
}

.down{
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
}
.down p{
    margin: 0px;
}
.socials{
    margin-top: 20px;
    background: #24495F;
    padding: 20px 80px;
    display: flex;
    justify-content: space-between;
}
.socials p{
    margin-top: 10px;
}
.socials a{
   text-decoration: none;
}

.btn{
    color: white;
}
.btn:hover{
    background: white;
    color: #214C69;
}


.email{
    height: 50px;
    padding:  0 10px;
    border-radius: 10px;
}
.call{
    display: flex;
    align-items: center;
    justify-content: center;
}

.social-icons{
        display: flex;
        justify-content: space-between;
}
.social-icons a{
   
    margin: 10px;
}

/* In your CSS file */
/* @keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  @keyframes shine {
    0% { border-color: #f0f; }
    50% { border-color: #0ff; }
    100% { border-color: #f0f; }
  }
  
  .blink {
    position: relative;
    border: 2px solid #f0f;
    padding: 10px 15px;
    background: #ff0000;
    color: #fff;
    font-size: 16px;
    animation: blink 1s infinite, shine 2s infinite;
  }
   */

@media (max-width: 768px) {
    .footer-top-img{
        width: 100%;
    }

    

    .top img{
        height: 40px;
        width: 40px;
    }
    .top p{
        font-size: 15px;
    }
    .horizantal {
        flex-direction: column;
        align-items: center;
        text-align: center; /* Center align the text and content */
        padding-bottom: 0px;
    }

    .horizantal > div {
        width: 100%; /* Make each section take full width */
        display: flex;
        justify-content: center; /* Center align the content horizontally */
        margin-bottom: 20px; /* Add spacing between sections */
    }

   .vertical{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
   }
  

    .horizantal .Special-section,
    .horizantal .Account-shipping,
    .horizantal .Newsletter {
        align-items: center; /* Center align items within each section */
    }
    .socials{
        flex-direction: column;
        align-items: center;
    }
    .address {
        display: flex;
        flex-direction: column; /* Stack items vertically on small devices */
        align-items: center; /* Center align items */
    }
    
    .left{
        display: none;
    }
    .right {
        width: 100%; /* Full width for each section */
        text-align: center; /* Center align text */
    }
    
    .right {
        padding-right: 0; /* Remove padding on small devices */
        margin-top: 20px; /* Add some spacing between the left and right sections */
    }
    
    .up {
        display: flex;
        flex-direction: column; /* Stack items vertically */
        align-items: center; /* Center align items */
    }
    
    .down {
        display: flex;
        flex-direction: column; /* Stack items vertically */
        align-items: center; /* Center align items */
      
    }
    .text{
        width: 200px;
    }
    
   
    
}

    


